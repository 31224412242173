import React, { useState, useEffect, useContext } from "react";
import { API_BASE_URL } from "../config/connect";
import Pagination from "../components/Pagination";
import { NavLink } from "react-router-dom";
import Trendingproducts from "../components/Trendingproducts";
import ViemoreProdSkeleton from "../components/ViemoreProdSkeleton";
import ProgressBar from "../components/ProgressBar";
import Nodata from "../components/Nodata";
import Header from "../components/Header";
import { useProductData } from "../components/ProductsContextApi";

function Productslist() {

  const allprods = useProductData().allProductData;
  const bids = allprods?.filter((product) => product.bidstender == '1');


  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [numBidders, setNumBidders] = useState({});
  const [loading, setLoading] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };

  // const getAllBitsandTender = () => {
  //   fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=bidsandtender`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setBitsandTender(json["results"]);
  //       setLoading(false);
  //     });
  // };

  const getNoOfBidders = (productcode) => {
    return fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=biddingperitem&prcode=${productcode}`
    )
      .then((response) => response.json())
      .then((json) => json["results"].length);
  };


  useEffect(() => {
    // getAllBitsandTender();

    if (!bids || !bids.length) return;

    bids.forEach((bid) => {
      getNoOfBidders(bid.code)
        .then((num) => {
          setNumBidders((prevNumBidders) => ({
            ...prevNumBidders,
            [bid.code]: num,
          }));
        })
        .catch((error) => {
          console.error("Error fetching number of bidders:", error);
        });
    });
  }, [bids]);

  return (
    <div>
    <Header/>
      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              {/* Sidebar */}
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>

              {/* Main Content */}
              <div className="col-xl-9 col-lg-8">
                {/* Shop Top Section */}
                <div className="shop-top-wrap mb-35">
                  {/* Shop Top Left */}
                  <div className="shop-top-left">
                    <h5 className="title">Bids and Tender</h5>
                  </div>
                  {/* Shop Top Right */}
                  <div className="shop-top-right">
                    {/* Form and Select for Sorting */}
                    {/* Icons for View Options */}
                    <ul>
                      <li>View</li>
                      <li className="active">
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Product Listing */}
                {loading && <ViemoreProdSkeleton />}
                {!loading && bids.length === 0 && <Nodata />}
                {!loading && bids.length > 0 && (
                  <div className="row justify-content-center">
                    {bids.map((bid) => (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-8"
                        key={bid.code}
                      >
                        <div className="shop-product-item mb-30">
                          {/* Shop Thumb */}
                          <div className="shop-thumb">
                            <NavLink to={`bidstenderdetails/${bid.code}`}>
                              <img src={bid.previewpicture || bid.picture} alt="img" />
                            </NavLink>
                            <span>New</span>
                          </div>
                          {/* Shop Content */}
                          <div className="shop-content">
                            <ul className="tag">
                              <li>
                                Sold by{" "}
                                <NavLink to="">{bid.sellername}</NavLink>
                              </li>
                            </ul>
                            <h2 className="title">
                              <NavLink to="">{bid.name}</NavLink>
                            </h2>
                            <span>
                              Closing Date: {`${bid.closingdate} `}
                            </span>
                            <ProgressBar
                              eventEndTime={bid.closingdate}
                            />
                            {/* Price */}
                            <div className="content-bottom">
                              <h4 className="price">
                                {`₵${bid.price.toLocaleString()}`}
                              </h4>
                              {/* Number of Bidders */}
                              <p>
                                {`${numBidders[bid.code] > 0 ? numBidders[bid.code] : "0"} bidding`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Shop Bottom Section (Pagination and Options) */}
                <div className="shop-bottom-wrap">
                  <div className="shop-bottom-top">
                    <h5 className="title">Shop</h5>
                    <p>
                      Showing {Math.min(indexOfFirstPost + 1, bids.length)} to{" "}
                      {Math.min(indexOfLastPost, bids.length)} of{" "}
                      {bids.length} Item(s)
                    </p>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={bids.length}
                      paginate={paginate}
                    />
                  </div>
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      {/* Form for Filtering */}
                    </div>
                    <div className="shop-bottom-right">
                      {/* Icons for View Options */}
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Productslist;
