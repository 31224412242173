import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/mCustomScrollbar.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/slick.css";
import "./assets/css/jquery-ui.css";
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Products from "./views/Products";
import Profservices from "./views/Profservices";
import Bluecollar from "./views/Bluecollar";
import Productslist from "./views/Productslist";
import Cart from "./views/Cart";
import Commodities from "./views/Commodities";
import Shopdetail from "./views/Shopdetail";
import CheckoutSummary from "./views/CheckoutSummary";
import RequestService from "./views/RequestService";
import ProductDetailsPage from "./components/ProductDetailsPage";
import BidsTenderDetails from "./views/BidsTenderDetails";
import RelatedProductsPage from "./views/RelatedProductsPage";
import MyMessages from "./views/MyMessages";
import MessageDetails from "./views/MessageDetails";

import { Provider } from "react-redux";
import store from "./store/store";
import Wishlist from "./views/Wishlist";
import Joinlivemarkets from "./views/Joinlivemarkets";
import CompareProducts from "./views/CompareProducts";
import RelatedDetails from "./views/RelatedDetails";
import Shopitems from "./views/Shopitems";
import CheckoutPayment from "./views/CheckoutPayment";
import CheckoutAddress from "./views/CheckoutAddress";
import CheckoutServiceSummary from "./views/CheckoutServiceSummary";
import CheckoutServicePayment from "./views/CheckoutServicePayment";
import LoginTemplate from "./components/LoginTemplate";
import OrdersTemplate from "./components/OrdersTemplate";
import Account from "./views/Account";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Contact from "./views/Contact";
import About from "./views/About";
import Privacy from "./views/Privacy";
import PaymentMethods from "./views/Paymentm";
import Termcon from "./views/Termcon";
import { ProductsContextApi } from "./components/ProductsContextApi";
import FAQ from "./views/Faq";
import Returns from "./views/Returns";
import OrderHow from "./views/OrderHow";
import CheckoutPay from "./views/CheckoutPay";

function App() {
 
  return (
    <ProductsContextApi>
      <Router>
        <div className="App">
          <Provider store={store}>
            <Routes>
              <Route exact path="/" element={<Home />}>
                {" "}
              </Route>{" "}
              <Route exact path="/products/:brandcode" element={<Products />}>
                {" "}
              </Route>{" "}
              <Route exact path="/profservices" element={<Profservices />}>
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/service/request/:prodcode"
                element={<RequestService />}
              >
                {" "}
              </Route>{" "}
              <Route exact path="/bluecollar" element={<Bluecollar />}>
                {" "}
              </Route>{" "}
              <Route exact path="/productslist" element={<Productslist />}>
                {" "}
              </Route>{" "}
              <Route exact path="/cart" element={<Cart />}>
                {" "}
              </Route>{" "}
              <Route exact path="/wishlist" element={<Wishlist />}>
                {" "}
              </Route>{" "}
              <Route exact path="/compare" element={<CompareProducts />}>
                {" "}
              </Route>{" "}
              <Route exact path="/commodities" element={<Commodities />}>
                {" "}
              </Route>{" "}
              <Route exact path="/shopdetail/:prodcode" element={<Shopdetail />}>
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/productslist/bidstenderdetails/:prodcode"
                element={<BidsTenderDetails />}
              >
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/checkout/summary"
                element={<CheckoutSummary />}
              >
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/viewmore/:prodtype"
                element={<ProductDetailsPage />}
              >
                {" "}
              </Route>{" "}
              <Route exact path="/shopitems/:shopcode" element={<Shopitems />}>
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/relatedproduct"
                element={<RelatedProductsPage />}
              >
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/joinlivemarkets"
                element={<Joinlivemarkets />}
              >
                {" "}
              </Route>{" "}
              <Route
                exact
                path="relatedproduct/shopdetail/:prodcode"
                element={<RelatedDetails />}
              >
                {" "}
              </Route>{" "}
              <Route
                exact
                path="/checkout/payment"
                element={<CheckoutPayment />}
              />{" "}
              <Route
                exact
                path="/checkout/address"
                element={<CheckoutAddress />}
              />
              Contact{" "}
              <Route
                exact
                path="/checkout/servicesummary"
                element={<CheckoutServiceSummary />}
              />{" "}
              <Route
                exact
                path="/checkout/servicepayment"
                element={<CheckoutServicePayment />}
              />{" "}
              <Route exact path="/login" element={<LoginTemplate />} />{" "}
              <Route exact path="/orders" element={<OrdersTemplate />} />{" "}
              <Route exact path="/account" element={<Account />} />{" "}
              <Route
                exact
                path="login/forgotpass"
                element={<ForgotPassword />}
              />{" "}
              <Route exact path="/resetpass" element={<ResetPassword />} />{" "}
              <Route exact path="/contact" element={<Contact />} />{" "}
              <Route exact path="/about" element={<About />} />{" "}
              <Route exact path="/privacy" element={<Privacy />} />{" "}
              <Route exact path="/paymentm" element={<PaymentMethods />} />{" "}
              <Route exact path="/termcon" element={<Termcon />} />{" "}
              <Route exact path="/faq" element={<FAQ />} />{" "}
              <Route exact path="/return" element={<Returns />} />{" "}
              <Route exact path="/orderhow" element={<OrderHow />} />{" "}
              <Route
                exact
                path="messages/:mescode"
                element={<MessageDetails />}
              />{" "}
              <Route exact path="/messages" element={<MyMessages />} />{" "}
              <Route exact path="/checkout/pay" element={<CheckoutPay />} />{" "}
            </Routes>{" "}
          </Provider>{" "}
        </div>{" "}
        <Footer />
      </Router>
    </ProductsContextApi>
  );
}

export default App;
