import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import sidebar_product01 from "../assets/img/product/sidebar_product01.jpg";
import sidebar_product02 from "../assets/img/product/sidebar_product02.jpg";
import sidebar_product03 from "../assets/img/product/sidebar_product03.jpg";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import Trendingproducts from "../components/Trendingproducts";
import ViemoreProdSkeleton from "../components/ViemoreProdSkeleton";

function Joinlivemarkets() {
  const [marketlist, setMarketlist] = useState("");

  const gettAllMarketlist = () => {
    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=livemarkets&svg=list`)
      .then((response) => response.json())
      .then((json) => {
        //console.log(json['results']);
        setMarketlist(json["results"].slice(0, 100));
      });
  };

  useEffect(() => {
    gettAllMarketlist();
  }, []);

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = marketlist.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  return (
    <div>
      <Header />
      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">
                      Live Markets:: Stores and Products
                    </h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">All Markets</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                          <option value="">Sorting</option>
                                          <option>Free Shipping</option>
                                          <option>Best Match</option>
                                          <option>Newest Item</option>
                                          <option>Size A - Z</option>
                                      </select> */}
                    </form>
                    {/* <ul>
                    <li>View</li>
                    <li className="active">
                      <NavLink to="">
                        <i className="fa-solid fa-table-cells"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <i className="fa-solid fa-bars"></i>
                      </NavLink>
                    </li>
                  </ul> */}
                  </div>
                </div>
                <div className="row justify-content-center">
                  {/*
                   ** Live Market List starts here
                   */}

                  {currentPosts.length > 0 ? (
                    currentPosts.map((post, index) => {
                      return (
                        <div
                          key={index}
                          className="col-xl-6 col-lg-6 col-md-6 col-sm-8"
                        >
                          <div className="shop-product-item mb-50">
                            <div className="shop-thumb">
                              <NavLink to={`/shopitems/${post.vendorcode}`}>
                                <img
                                  src={post.image}
                                  alt="img"
                                  width="370px"
                                  height="500px"
                                />
                              </NavLink>
                              <span>Premium</span>
                            </div>
                            <div className="shop-content">
                              <h2 className="title">
                                <NavLink to={`/shopitems/${post.vendorcode}`}>
                                  {post.title}
                                </NavLink>
                              </h2>
                              <ul className="tag">
                                <li>
                                  {post.description}
                                  <NavLink to="vendor-profile.html">{}</NavLink>
                                </li>
                              </ul>
                              <div className="rating">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>

                              <div className="content-bottom">
                                <h5 className="price">{post.vendorname}</h5>
                                <NavLink to={`/shopitems/${post.vendorcode}`}>
                                  {" "}
                                  <p>
                                    <span>Visit Shop Now</span>
                                  </p>{" "}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <ViemoreProdSkeleton />
                  )}

                  {/*Live Market list ends here*/}
                </div>
                <div className="shop-bottom-wrap">
                  {marketlist.length > 0 ? (
                    <div className="shop-bottom-top">
                      <h5 className="title">Shop</h5>
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > marketlist.length
                          ? marketlist.length
                          : indexOfLastPost}{" "}
                        of {marketlist.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={marketlist.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                              <option value="">Show 09</option>
                                              <option>Show 12</option>
                                              <option>Show 08</option>
                                              <option>Show 06</option>
                                              <option>Show 03</option>
                                          </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                              <option value="">Default sorting</option>
                                              <option>Free Shipping</option>
                                              <option>Best Match</option>
                                              <option>Newest Item</option>
                                              <option>Size A - Z</option>
                                          </select> */}
                      </form>
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Joinlivemarkets;
