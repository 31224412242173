import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Leftmenu from "../components/Leftmenu";
import Navbar from "../components/Navbar";
import { API_BASE_URL } from "../config/connect";
import Pagination from "../components/Pagination";
import Topbar from "../components/Topbar";
import Livemarket from "../components/Livemarket";
import Bluecollarsing from "../components/Bluecollarsing";
import Header from "../components/Header";
import Trendingproducts from "../components/Trendingproducts";

function Bluecollar() {
  const [products, setProducts] = useState("");
  const [loading, setLoading] = useState(true);

  const getAllProducts = () => {
    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=bluecollar&svg=list&cat=CAT65eb2b04466f9`)
      .then((response) => response.json())
      .then((json) => {
        //console.log(json['results'])
        setProducts(json["results"]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  return (
    <div>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">Artisans Services</h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">sort By</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                            <option value="">Sorting</option>
                                            <option>Free Shipping</option>
                                            <option>Best Match</option>
                                            <option>Newest Item</option>
                                            <option>Size A - Z</option>
                                        </select> */}
                    </form>
                    <ul>
                      <li>View</li>
                      <li className="active">
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <Bluecollarsing
                    slicedProducts={currentPosts}
                    loading={loading}
                  />
                </div>
                <div className="shop-bottom-wrap">
                  {products.length > 0 ? (
                    <div className="shop-bottom-top">
                      <h5 className="title">Shop</h5>
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > products.length
                          ? products.length
                          : indexOfLastPost}{" "}
                        of {products.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={products.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Show 09</option>
                                                <option>Show 12</option>
                                                <option>Show 08</option>
                                                <option>Show 06</option>
                                                <option>Show 03</option>
                                            </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Default sorting</option>
                                                <option>Free Shipping</option>
                                                <option>Best Match</option>
                                                <option>Newest Item</option>
                                                <option>Size A - Z</option>
                                            </select> */}
                      </form>
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Bluecollar;
