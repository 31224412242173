import React, { useState, useEffect } from "react";
//import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import "../assets/css/Table.css";
import Header from "./Header";
import Pagination from "../components/Pagination";

const styles = `
tr{
    // border: none !important;
   // background-color:#f2f0f0;
    padding:150px !important;
}
tr:hover{
    box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.24);
    cursor:pointer;
}
th{
    border:none !important;
    text-decoration:none !important;
}
td{
    border-top:none !important;
    border-right:none !important;
    border-left:none !important;
    border-bottom: solid 1px #ebe8e8 !important;
}

thead{
    border:none !important
}
.card{
    // box-shadow: 0px 1px 10px 5px rgba(0,0,0,0.08);
    border-radius:10px;
    border:none;
}


`;

function OrdersTemplate() {
  const [order, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    let indexOfFirstPost = indexOfLastPost - postsPerPage;
    const slicedOrder = order.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (data) => {
      let currentNumber = data.selected + 1;
      setCurrentPage(currentNumber);
    };
    //Pagination data ends here

  const getOrders = () => {
    const customcode = localStorage.getItem("customcode");
    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=requestservice&svg=ordersinfo&cucode=${customcode}`)
      .then((response) => response.json())
      .then((json) => {
        setOrders(json['results']);
        setLoading(false);


           // const descript = json["results"][0].description;
        // setName(json["results"][0].name);
        // setPrice(json["results"][0].price);
        // setDescription(descript.replace("'", "'"));
        // setPicture(json["results"][0].picture);
        // setModel(json["results"][0].model);
        // setServicetype(json["results"][0].servicetype);
        // setSellercode(json["results"][0].sellercode);
        // setSellerName(json["results"][0].sellername);
        // setCategoryCode(json["results"][0].categorycode);
        // setSubcategoryCode(json["results"][0].subcategorycode);
        // setBrand(json["results"][0].brand);
        // setSKU(json["results"][0].sku);
        // setRegion(json["results"][0].region);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      <Header />
      <section>
        <div className="container pb-30 pt-30">
          <style>{styles}</style>
          <div className="row container d-flex justify-content-center">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Order Code</th>
              <th>Image</th>
              <th>Name</th>
              <th>Amount (Gh₵)</th>
              <th>Type</th>
              <th>Orderdate</th>
              <th>Payment Status</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
          <td colSpan="8" style={{textAlign:"center"}} >
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
         </td>
            ) : slicedOrder.length > 0 ? (
              slicedOrder.filter(slicedOrder => slicedOrder.paystate === '1').map((orders) => (
                <tr key={orders.id}>
                  <td>{orders.trackingcode}</td>
                  <td><img src={orders.previewpicture || orders.picture} alt="" style={{width: '100px', height:'auto', objectFit:'cover'}} /></td>
                  <td>{orders.productname}</td>
                  <td>{orders.total !== null && orders.total !== undefined && orders.total !== '' ? parseFloat(orders.total).toFixed(2) : '-'}</td>
                  <td>{orders.servicetype === '1' ? 'Service' : 'Product'}</td>
                  <td>{new Date(orders.orderdate).toLocaleDateString()}</td>
                  <td>
                    {orders.servicetype === '2' && (orders.trackingstatus === "3" ? 'Paid' : 'Not paid')}
                    {orders.servicetype === '1' && (orders.trackingstatus === "3" ? 'Paid' : 'Not paid')}
                  </td>
                  <td>
                    {orders.servicetype === '2' && (orders.trackingstatus === "1" ? 'Pending dispatch' : 'Pending delivery')}
                    {orders.servicetype === '1' && (orders.trackingstatus === "1" ? 'Pending quote' : 'Pending delivery')}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No orders available</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="shop-bottom-wrap">
                  {order.length > 0 ? (
                    <div className="shop-bottom-top">
                      {/* <h5 className="title">Orders</h5> */}
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > order.length
                          ? order.length
                          : indexOfLastPost}{" "}
                        of {order.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={order.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  </div>

      </div>

      </div>
              </div>
            </div>
          </div>
        </div>
      </section>

   
    </>
  );
}

export default OrdersTemplate;
