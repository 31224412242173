import React, { useEffect, useState } from "react";

import megamenu_banner from "../assets/img/images/megamenu_banner.jpg";
import { NavLink, Link } from "react-router-dom";
import { API_BASE_URL } from '../config/connect'
import Leftmenusubcat from "./Leftmenusubcat";

function Leftmenu() {
 
  const pathname = window.location.pathname;

  const swingtoggle = pathname === '/'?true:false ;
 
  const [toggleCategory, setToggleCategory] = useState(swingtoggle);

  const [categories, setCategories] = useState([]);
 

  /*
   * The following E6 functions will get all categories and
   * subcategories from the API
   */
  // const allitemscategories = localStorage.getItem('itemcategories');

  // if(allitemscategories !== null){
  //   const getAllCategories = () => {
  //     setCategories(allitemscategories)
  //   }

  // }


  const getAllCategories = () => {
    const allitemcategories = JSON.parse(localStorage.getItem('itemcategories'));

    if(Array.isArray(allitemcategories) && allitemcategories.length > 0 ){
      setCategories(allitemcategories);
    
    }else{

    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=categories&svg=list&cls=min`)
      .then(response => response.json())
      .then(json => {
       // console.log(json['results'])
        setCategories(json['results'])
        //Save category list in localstorage
        localStorage.setItem('itemcategories', JSON.stringify(json['results']) );

        //localStorage.removeItem('itemcategories');
  
    });
  }
 }


  useEffect(() => {
    getAllCategories() ;
  }, []);

  return (
    <div>
      <div className="header-category">
        <Link
          className="cat-toggle"
          onClick={(e) => {
            e.preventDefault();
            setToggleCategory(!toggleCategory);
          }}
        >
          <i className="fas fa-bars"></i>
          Browse Categories
          <i className="fas fa-angle-down"></i>
        </Link>
        <ul
          className="category-menu"
          style={{ display: toggleCategory ? "block" : "none" }}
        >
          {/* <li className="add-megamenu">
            <NavLink to="">
            <i className="fa-sharp fa-solid fa-shop"></i>All Categories
            </NavLink>
          </li> */}
        
    
          {
          categories? categories.map((post, index) => {

        return  <li key={index} className="menu-item-has-children">
            <NavLink to="/">
              <i className={post.icon}></i>{post.name}{" "}
            </NavLink>
            <ul className="megamenu">
              <Leftmenusubcat catcode={post.code} />
              
              <li className="sub-column-item">
                {/* <NavLink to="/">Audio & Video</NavLink> */}
                <ul>
                  <li className="mega-menu-banner">
                    <NavLink to="/">
                      <img src={megamenu_banner} alt="megamenu_banner" />
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

}):''  } 

          {/* <li className="more-categories">
            All Categories
            <i className="fas fa-chevron-right"></i>
          </li> */}
        </ul>
      </div>
    </div>
    
  );
}

export default Leftmenu;
