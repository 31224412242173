import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const BandMaterial = ({ onBandMaterialSelected }) => {
  const [selectedBandMaterial, setSelectedBandMaterial] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.bandmaterial) {
        const bandMaterialObject = firstResult.bandmaterial;
        if (bandMaterialObject.trim() !== "") {
          setVariations(bandMaterialObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleBandMaterialClick = (bandmaterial) => {
    setSelectedBandMaterial(bandmaterial);
    onBandMaterialSelected(bandmaterial);
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Band Material:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedBandMaterial === variation ? "selected" : ""}`}
            onClick={() => handleBandMaterialClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default BandMaterial;
