import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL } from "../config/connect";

const StarRating = ({ prodcode }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  useEffect(() => {
    // Retrieve the rating from local storage when the component mounts
    const storedRating = localStorage.getItem("rating");
    if (storedRating) {
      setRating(parseInt(storedRating));
    }
  }, []);

  const handleRatingClick = async (ratingValue) => {
    try {
      setRating(ratingValue);
      // Save the rating to local storage
      localStorage.setItem("rating", ratingValue);
      
      const data = { rating: ratingValue, productCode: prodcode };
      console.log("Rating submitted:", ratingValue);
      // console.log(data);
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=ratingpost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Post request successful:", responseData);

      if (responseData.success === true) {
        notify("Subscription successfully added");
      } else if (responseData.error === "alreadyexists") {
        notifyError("This email already has a subscription");
      }
    } catch (error) {
      console.error("Error making POST request:", error);
      // Handle errors
    }
  };

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              style={{ display: "none" }}
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => handleRatingClick(ratingValue)}
            />
            <FaStar
              className="star"
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
              size={30}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
            />
          </label>
        );
      })}
      <ToastContainer />
    </div>
  );
};

export default StarRating;
