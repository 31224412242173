import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Viewmore from "../views/Viewmore";

// This function gets all superdeals from the db
const GetAllSuperDeals = async () => {
  try {
    const response = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=superdeals`
    );
    const json = await response.json();
    return json["results"];
  } catch (error) {
    console.error("Error fetching super deals:", error);
    throw error;
  }
};

const GetAllHotProducts = async () => {
  try {
    const response = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=hotproduct`
    );
    const json = await response.json();
    return json["results"];
  } catch (error) {
    console.error("Error fetching hot products:", error);
    throw error;
  }
};

const getTopSelection = async () => {
  try {
    const response = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=topselection`
    );
    const json = await response.json();
    return json["results"];
  } catch (error) {
    console.error("Error fetching top selections:", error);
    throw error;
  }
};

const getNewArrivals = async () => {
  try {
    const response = await fetch(
      `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=newarrivals`
    );
    const json = await response.json();
    return json["results"];
  } catch (error) {
    console.error("Error fetching new arrivals:", error);
    throw error;
  }
};

// Add function for other product types

// This json is the data passed to the viewmore components for the details of each page
const viewmoreData = {
  superdeals: {
    pageTitle: "Super Deals",
    productData: GetAllSuperDeals,
  },

  hotproducts: {
    pageTitle: "Hot Products",
    productData: GetAllHotProducts,
  },

  topselection: {
    pageTitle: "Top Selection",
    productData: getTopSelection,
  },

  newarrivals: {
    pageTitle: "New Arrivals",
    productData: getNewArrivals,
  }
  // Add more entries for other product types
};

// This function will render the list of products based on the path provided
const ProductDetailsPage = () => {
  const { prodtype } = useParams();
  const selectedData = viewmoreData[prodtype];
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedData.productData) {
        try {
          const data = await selectedData.productData();
          setProducts(data);
        } catch (error) {
          // Handle errors
        }
      }
    };

    fetchData();
  }, []);

  return <Viewmore {...selectedData} products={products} />;
};

export default ProductDetailsPage;
