import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const EnergyEfficiency = ({ onEnergyEfficiencySelected }) => {
  const [selectedEnergyEfficiency, setSelectedEnergyEfficiency] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.energyefficiency) {
        const energyEfficiencyObject = firstResult.energyefficiency;
        if (energyEfficiencyObject.trim() !== "") {
          setVariations(energyEfficiencyObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleEnergyEfficiencyClick = (energyefficiency) => {
    setSelectedEnergyEfficiency(energyefficiency);
    onEnergyEfficiencySelected(energyefficiency);
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Energy Efficiency:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedEnergyEfficiency === variation ? "selected" : ""}`}
            onClick={() => handleEnergyEfficiencyClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default EnergyEfficiency;
