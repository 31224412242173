import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const Modifications = ({ onModificationsSelected }) => {
  const [selectedModifications, setSelectedModifications] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.modifications) {
        const modificationsObject = firstResult.modifications;
        if (modificationsObject.trim() !== "") {
          setVariations(modificationsObject.split(","));
        }
      }
    } catch (error) {
      // Handle error here
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  const handleModificationsClick = (modifications) => {
    setSelectedModifications(modifications);
    onModificationsSelected(modifications);
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Modifications:</span>
      <div className="sizes">
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedModifications === variation ? "selected" : ""}`}
            onClick={() => handleModificationsClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default Modifications;
