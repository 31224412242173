import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../config/connect";
import { NavLink } from "react-router-dom";
import add_banner_slider01 from "../assets/img/images/add_banner_slider01.jpg";
import add_banner_img02 from "../assets/img/images/add_banner_img02.jpg";
import Trendingproducts from "./Trendingproducts";
import ViemoreProdSkeleton from "./ViemoreProdSkeleton";


function Flashsales() {
  const [flashsales, setFlashsales] = useState("");
  // const [popularsales, setPopularsales] = useState("");
  const [isFlash, setFlash] = useState(true);
  // const [isPopular, setPopular] = useState(false);
  // const [isTopRate, setTopRate] = useState(false);
  // const [trendingProducts, setTrendingProducts] = useState(false);

  //functions to switch between the flashsales/popular/toprated view
  const switchThemesFlash = () => {
   // setTopRate(false);
   // setPopular(false);
    setFlash(true);
  };
  // const switchThemesPopular = () => {
  //   setFlash(false);
  //   setTopRate(false);
  //   setPopular(true);
  // };
  // const switchThemesRate = () => {
  //   setFlash(false);
  //   setPopular(false);
  //   setTopRate(true);
  // };

  const styles = {
    height: "200px",
    objectFit: "cover",
  };
  const getAllFlashsales = () => {
    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=flashsales&cls=min`)
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"]);
        setFlashsales(json?.results?.slice(0, 8) || []);
      });
  };
  // const getAllPopularSales = () => {
  //   fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=superdeals&cls=min`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json["results"]);
  //       setPopularsales(json?.results?.slice(0, 8) || []);
  //     });
  // };

  // const getTrendingProducts = () => {
  //   fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=trendingproducts`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       // console.log(json["results"]);
  //       setTrendingProducts(
  //         json?.results
  //           ?.sort((a, b) => b.wishlistcount - a.wishlistcount)
  //           ?.slice(0, 6) || []
  //       );
  //     });
  // };
  useEffect(() => {
    getAllFlashsales();
   // getAllPopularSales();
    // getTrendingProducts();
  }, []);

  // console.log(trendingProducts);
  return (
    <div>
      <section className="flash-product-area pt-90 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10">
              <div className="slider-add-banner banner-active mb-45">
                <div className="add-banner-container">
                  <div className="add-banner">
                    <div className="add-banner-img">
                      <a href="shop.html" className="add-banner-img-link ">
                        <img
                          src={add_banner_slider01}
                          alt="add_banner_slider01"
                          className="add-banner-img"
                        />
                      </a>
                    </div>
                    <div className="add-banner-content">
                      <span>35% discount</span>
                      <h2 className="title">Smart Phone</h2>
                      <p>Reference site about </p>
                      <a href="shop.html" className="btn">
                        shop now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <Trendingproducts>Trending Products</Trendingproducts>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="add-banner-thumb mb-55">
                <a href="/" className="add-banner-link">
                  <img
                    src={add_banner_img02}
                    alt="add_banner_img02"
                    className="fading-image"
                  />
                </a>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="section-title title-style-two mb-30">
                    <h4 className="title"> Flash Sales Today!
                      {/* {isFlash
                        ? "Flash Sales Today!"
                        : isPopular
                        ? "Popular Sales"
                        : isTopRate
                        ? "Top Rated Sales"
                        : ""} */}
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="product-menu-nav">
                    <button
                      className={isFlash ? `active` : ``}
                      onClick={switchThemesFlash}
                      data-filter="*"
                    >
                      Flash
                    </button>
                    {/* <button
                      className={isPopular ? `active` : ``}
                      onClick={switchThemesPopular}
                      data-filter=".cat-one"
                    >
                      Popular
                    </button>
                    <button
                      className={isTopRate ? `active` : ``}
                      onClick={switchThemesRate}
                      data-filter=".cat-two"
                    >
                      Top Rate
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="flash-product-item-wrap">
                <div className="row flash-isotope-active">
                  {/********************************* Flash Sales *******************************/}
                  {isFlash && (
                    <div className="flash-product-item-wrap">
                      <div className="row flash-isotope-active">
                        {flashsales.length > 0 ?
                          flashsales.map((sale) => {
                            const prodcode = { itemcode: sale.code };
                            return (
                              <div
                                className="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
                                key={sale.code}
                              >
                                <div className="product-item-two mb-30">
                                  <div className="product-thumb">
                                    <NavLink to={`shopdetail/${sale.code}`}>
                                      <img
                                        src={sale.previewpicture || sale.picture}
                                        alt="img"
                                        style={styles}
                                      />
                                    </NavLink>
                                  </div>
                                  <div className="product-content">
                                    <h6 className="title">
                                      <NavLink to={`shopdetail/${sale.code}`}>
                                        {sale.name}
                                      </NavLink>
                                    </h6>
                                    <h4 className="price">
                                      {`₵${sale.price.toLocaleString()}`}
                                      <span>-35%</span>
                                    </h4>
                                    <div className="content-bottom">
                                      <ul>
                                        {/* <li>1k+ Orders ~</li> */}
                                        <li>
                                          <i className="fa-solid fa-star"></i>
                                         {Math.floor(sale.ratingaverage) }
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }) : <ViemoreProdSkeleton/>}
                      </div>
                    </div>
                  )}

                  {/********************************* Popular Sales *******************************/}

                  {/* {isPopular && (
                    <div className="flash-product-item-wrap">
                      <div className="row flash-isotope-active">
                        {popularsales.length > 0 ?
                          popularsales.map((sale) => {
                            return (
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two">
                                <div className="product-item-two mb-30">
                                  <div className="product-thumb">
                                    <NavLink to={`shopdetail/${sale.code}`}>
                                      <img
                                        src={sale.picture}
                                        alt="img"
                                        style={styles}
                                      />
                                    </NavLink>
                                  </div>
                                  <div className="product-content">
                                    <h6 className="title">
                                      <NavLink to={`shopdetail/${sale.code}`}>
                                        {sale.name}
                                      </NavLink>
                                    </h6>
                                    <h4 className="price">
                                      {`₵${sale.price}`}
                                      <span>-35%</span>
                                    </h4>
                                    <div className="content-bottom">
                                      <ul>
                                        <li>1k+ Orders ~</li>
                                        <li>
                                          <i className="fa-solid fa-star"></i>
                                          4.9
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }): <ViemoreProdSkeleton/>}
                      </div>
                    </div>
                  )} */}

                  {/********************************* Top Rated Sales *******************************/}

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Flashsales;