import { useState, useEffect } from "react";
import Modal from "react-modal";
import { API_BASE_URL } from "../config/connect";

const SizeGuideModal = ({ isOpen, onRequestClose }) => {
  const [activeTab, setActiveTab] = useState("ds");
  const [sizesguide, setSizesGuide] = useState([]);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const getSizeGuide = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=sizeguide`
      );
      const json = await response.json();
      setSizesGuide(json["results"]);
      // console.log(sizesguide);
      //console.log("testing the fetch");
    } catch (error) {
      console.error("Error fetching product variations:", error);
      // Handle error here
    }
  };

  useEffect(() => {
    getSizeGuide();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="size-guide-modal"
      overlayClassName="size-guide-overlay"
    >
      <div className="table-container">
        <span className="close" onClick={onRequestClose}>
          &times;
        </span>
        <div className="container">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "ds" && "active"}`}
                onClick={() => handleTabClick("ds")}
                href="#ds"
              >
                Dresses & Skirts
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "ot" && "active"}`}
                onClick={() => handleTabClick("ot")}
                href="#ot"
              >
                Outerwear & Tops
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "je" && "active"}`}
                onClick={() => handleTabClick("je")}
                href="#je"
              >
                Jeans
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "tr" && "active"}`}
                onClick={() => handleTabClick("tr")}
                href="#tr"
              >
                Trousers
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "sh" && "active"}`}
                onClick={() => handleTabClick("sh")}
                href="#sh"
              >
                Shoes
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div
              id="ds"
              className={`tab-pane fade ${activeTab === "ds" && "show active"}`}
            >
              <div className="table-container">
                <table className="size-guide-table">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>UK</th>
                      <th>US</th>
                      <th>EU/IT</th>
                      <th>Bust</th>
                      <th>Natural Waist</th>
                      <th>Low Waist</th>
                      <th>Hip</th>
                      <th>Overarm</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizesguide.length > 0 ? (
                      sizesguide
                        .filter((guide) => guide.guidemaincode === "001") // Filter the sizesguide array based on the condition
                        .map((guide, i) => (
                          <tr key={i}>
                            <td>{guide.size}</td>
                            <td>{guide.uk}</td>
                            <td>{guide.us}</td>
                            <td>{guide.euit}</td>
                            <td>{guide.bust}</td>
                            <td>{guide.naturalwaist}</td>
                            <td>{guide.lowwaist}</td>
                            <td>{guide.hip}</td>
                            <td>{guide.overam}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="9">No size guide available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              id="ot"
              className={`tab-pane fade ${activeTab === "ot" && "show active"}`}
            >
              <div className="table-container">
                <table className="size-guide-table">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>UK</th>
                      <th>US</th>
                      <th>EU/IT</th>
                      <th>Bust</th>
                      <th>Natural Waist</th>
                      <th>Low Waist</th>
                      <th>Hip</th>
                      <th>Overarm</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizesguide.length > 0 ? (
                      sizesguide
                        .filter((guide) => guide.guidemaincode === "002") // Filter the sizesguide array based on the condition
                        .map((guide, i) => (
                          <tr key={i}>
                            <td>{guide.size}</td>
                            <td>{guide.uk}</td>
                            <td>{guide.us}</td>
                            <td>{guide.euit}</td>
                            <td>{guide.bust}</td>
                            <td>{guide.naturalwaist}</td>
                            <td>{guide.lowwaist}</td>
                            <td>{guide.hip}</td>
                            <td>{guide.overam}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="9">No size guide available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>{" "}
            </div>
            <div
              id="je"
              className={`tab-pane fade ${activeTab === "je" && "show active"}`}
            >
              <div className="table-container">
                <table className="size-guide-table">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>Low Waist</th>
                      <th>Hip</th>
                      <th>Inner Leg</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizesguide.length > 0 ? (
                      sizesguide
                        .filter((guide) => guide.guidemaincode === "003") // Filter the sizesguide array based on the condition
                        .map((guide, i) => (
                          <tr key={i}>
                            <td>{guide.size}</td>
                            <td>{guide.lowwaist}</td>
                            <td>{guide.hip}</td>
                            <td>{guide.innerleg}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="9">No size guide available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>{" "}
            </div>
            <div
              id="tr"
              className={`tab-pane fade ${activeTab === "tr" && "show active"}`}
            >
              <div className="table-container">
                <table className="size-guide-table">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>UK</th>
                      <th>US</th>
                      <th>EU/IT</th>
                      <th>Low Waist</th>
                      <th>Hip</th>
                      <th>Inner Leg</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizesguide.length > 0 ? (
                      sizesguide
                        .filter((guide) => guide.guidemaincode === "004") // Filter the sizesguide array based on the condition
                        .map((guide, i) => (
                          <tr key={i}>
                            <td>{guide.size}</td>
                            <td>{guide.uk}</td>
                            <td>{guide.us}</td>
                            <td>{guide.euit}</td>
                            <td>{guide.lowwaist}</td>
                            <td>{guide.hip}</td>
                            <td>{guide.innerleg}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="9">No size guide available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>{" "}
            </div>
            <div
              id="sh"
              className={`tab-pane fade ${activeTab === "sh" && "show active"}`}
            >
              <div className="table-container">
                <table className="size-guide-table">
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>UK</th>
                      <th>US</th>
                      <th>EU/IT</th>
                      <th>Inches</th>
                      <th>CM</th>
                      <th>China</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizesguide.length > 0 ? (
                      sizesguide
                        .filter((guide) => guide.guidemaincode === "005") // Filter the sizesguide array based on the condition
                        .map((guide, i) => (
                          <tr key={i}>
                             <td>{guide.uk}</td>
                            <td>{guide.us}</td>
                            <td>{guide.euit}</td>
                            <td>{guide.inches}</td>
                            <td>{guide.cm}</td>
                            <td>{guide.china}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="9">No size guide available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SizeGuideModal;
