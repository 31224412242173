import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const LevelSize = ({ onLevelSizeSelected }) => {
  const [selectedLevelSize, setSelectedLevelSize] = useState(null);
  const [variations, setVariations] = useState([]);
  const { prodcode } = useParams();

  const getAllProductVariations = async () => {
    try {
        const response = await fetch(
          `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
        );
        const json = await response.json();
        const firstResult = json["results"][0];
        if (firstResult && firstResult.levelsize) {
          const levelSizeObject = firstResult.levelsize;
          if (levelSizeObject.trim() !== "") {
            setVariations(levelSizeObject.split(","));
          }
        }
      } catch (error) {
        // Handle error here
      }
    };
  
    useEffect(() => {
      getAllProductVariations();
    }, [prodcode]);
  
    const handleLevelSizeClick = (levelsize) => {
      setSelectedLevelSize(levelsize);
      onLevelSizeSelected(levelsize);
    };
  
    return variations.length > 0 ? (
      <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
        <span style={{ fontSize: '', fontWeight: 'bold', color: 'black', marginTop: '12px' }}>Level Size:</span>
        <div className="sizes">
          {variations.map((variation, i) => (
            <div
              key={i}
              className={`size ${selectedLevelSize === variation ? "selected" : ""}`}
              onClick={() => handleLevelSizeClick(variation)}
            >
              {variation}
            </div>
          ))}
        </div>
      </div>
    ) : (
      ""
    );
  };
  
  export default LevelSize;
  