import React, { useState } from "react";
import Header from "../components/Header";
import Superdeals from "../components/Superdeals";
// import Categories from "../components/Categories";
import Ads from "../components/Ads";
import Flashsales from "../components/Flashsales";
import Hotproduct from "../components/Hotproduct";
// import PopularServices from "../components/PopularServices";
import Slider from "react-slick";
import front1 from "../assets/img/slider/front1.jpeg";
import front2 from "../assets/img/slider/front2.jpeg";
import front3 from "../assets/img/slider/front3.jpeg";
import front4 from "../assets/img/slider/front4.jpeg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL } from "../config/connect";
import ReactModal from "react-modal";
import GeneralInfoPopUp from "../components/GeneralInfoPopUp";

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  height: "100vh",
};

function Home() {
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const [data, setData] = useState({
    email: "",
  });
  //Set the state to false to disable the modal.
  const [isOpen, setIsOpen] = useState(true);


  const handleInput = (event) => {
    const { name, value } = event.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePostRequest = async () => {
    console.log(data);

    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=weeklysubscriberpost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      //check the response and return a notification
      const responseData = await response.json();
      console.log("Post request successful:", responseData);
      if (responseData.success === true) {
        notify("subscription succesfully added");
      } else if (responseData.error === "alreadyexits") {
        notifyError("This email already has a subscription");
      }

      // Handle the response data as needed
    } catch (error) {
      console.error("Error making POST request:", error);
      // Handle errors
    }
  };
  return (
    <>
      <div>
        {/* <!-- Scroll-top --> */}
        <button className="scroll-top scroll-to-target" data-target="html">
          <i className="fas fa-angle-up"></i>
        </button>
        {/* <!-- Scroll-top-end--> */}
        <Header />
        {/* <!-- main-area --> */}
        <main>
          <section className="banner-area pt-10 mb-0">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-xl-9">
                  <div className="row align-items-center">
                    <div className="col-lg-12 order-0 order-lg-2">
                      <div className="banner-img">
                        <Slider {...settings}>
                        <div>
                            <img src={front1} alt="" />
                          </div>
                          <div>
                            <img src={front2} alt="" />
                          </div>
                          <div>
                            <img src={front3} alt="" />
                          </div>
                          <div>
                            <img src={front4} alt="" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- banner-area-end --> */}

          {/* <!-- super-deals-area --> */}

          {/* <!-- super-deals-area-end --> */}
          <Superdeals />
          {/* <!-- choose-product-area --> */}
          <Ads />
          {/* <!-- choose-product-area-end --> */}
          <Hotproduct />
          {/* <!-- flash-product-area --> */}
          <Flashsales />

          {/* <PopularServices /> */}
          <section className="newsletter-area pb-90">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="newsletter-wrap">
                    <div className="newsletter-content">
                      <h2 className="title">
                        Sign Up for Weekly <span>Newsletter</span>
                      </h2>
                      <p>
                        Get 10% off new collection special Investigationes
                        demonstraverunt
                      </p>
                    </div>
                    <div className="newsletter-form">
                      <form>
                        <input
                          onChange={(event) => handleInput(event)}
                          type="text"
                          placeholder="Your email here..."
                          name="email"
                          id="email"
                        />
                        <button type="button" onClick={handlePostRequest}>
                          Subscribe
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- newsletter-area-end --> */}
        </main>
        {/* <!-- main-area-end --> */}
      </div>
      <ToastContainer />

      
      {/* <!-- General Info Modal --> */}
      {/* <ReactModal
        isOpen={isOpen}
        contentLabel="AfricaMarkets"
        onRequestClose={() => setIsOpen(false)}
        ariaHideApp={false}
      >
        <GeneralInfoPopUp />
      </ReactModal> */}
       {/* <!-- End General Info Modal --> */}
    </>
  );
}
export default Home;
